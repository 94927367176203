import Axios from "axios";
import Config from '../../config/env';
import writtenNumber from "written-number";
import numeral from "numeral";
const { ContainerPrinter } = Config

export const Print_Invoice = async (row) => {
    const {
        route,
        invoice_number,
        awb_number,
        agent_id,
        agent_perorangan,
        create_date,
        pieces,
        weight,
        rate_agent,
        publish_rate,
        amount_jaster_handling,
        amount_timbun,
        amount_surcharge,
        amount_shc,
        amount_pjkp2u,
        total_publish,
        total_agent,
        sub_total,
        total_ppn,
        total_amount,
        amount_ra,
        createdBy,
        commodity,
        dest,
        origin,
    } = row;

    const jaster_handling = amount_jaster_handling + amount_timbun + amount_surcharge + amount_shc;
    const empu_handling = (rate_agent === 0 ? publish_rate : rate_agent) * weight;

    const datasave = {
        "selectedPrinter": `invoice`,
        "master": {
            "invoice_number": invoice_number,
            "awb_number": awb_number,
            "customer": agent_id?.name || agent_perorangan,
            "tanggal_procces": create_date,
            "piecies": pieces,
            "weight": weight,
            "rate_agent": numeral(rate_agent === 0 ? publish_rate : rate_agent).format("0,000.00"),
            "publish_rate": numeral(publish_rate).format("0,000.00"),
            "empu_handling": numeral(empu_handling).format("0,000.00"),
            "jaster_handling": numeral(jaster_handling).format("0,000.00"),
            "ra_handling": numeral(amount_ra).format("0,000.00"),
            "PJKP2U": numeral(amount_pjkp2u).format("0,000.00"),
            "total_publish": numeral(total_publish).format("0,000.00"),
            "total_agent": numeral(total_agent).format("0,000.00"),
            "sub_total": numeral(sub_total).format("0,000.00"),
            "total_ppn": numeral(total_ppn).format("0,000.00"),
            "total_amount": numeral(total_amount).format("0,000.00"),
            "terbilang": `${writtenNumber(total_amount, { lang: "id" })} rupiah`,
            "petugas": `${createdBy?.name || "-"}`,
            "commodity": `${commodity}`,
            "outinc": `${route.toUpperCase()}`,
            "route_title": `${route === "outgoing" ? "TUJUAN" : "ORIGIN"}`,
            "route": `${route === "outgoing" ? dest?.code : origin?.code}`,
        },
        "detail": {
            "data": []
        }
    }

    try {

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const body = JSON.stringify({ ...datasave })

        await Axios.post(ContainerPrinter + 'printer/print', body, config)

    } catch (error) {
        alert("Harap periksa koneksi server printer")
    }
}