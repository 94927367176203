import React, { useEffect, useState } from 'react'
import Page from '../../../../../components/pages/Page';
import { useSelector, useDispatch } from "react-redux";
import { CheckCircleOutlined, ClearOutlined, FileTextOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';
import { message, Space, Card, Table, InputNumber, Button, Form, Modal, Result, Descriptions, Select, Divider, Input } from 'antd';
import { GetAPI, PostAPI } from '../../../../../redux';
import InvoiceList from './invoice_list';
import numeral from 'numeral';
import moment from 'moment';
import { Print_Invoice } from '../../../../../components/printComp/invoice_server';

export default function CashierOutgoing({ breadcrumb }) {

    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const [modalVisible, setModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(moment().unix());
    const [invoicesiListVisible, setInvoiceListVisible] = useState(false);
    const [disabledBtn, setdisbaleBtn] = useState(false);
    const [notif, setNotif] = useState("success");
    const [inputAwb, setInputAwb] = useState(null)
    const [awbs, setAwbs] = useState([]);
    const [awbsList, setAwbsList] = useState(null);
    const [agentRate, setRateAgent] = useState(0);

    const subtotaldefault = {
        publish_rate: 0,
        amout_ra: 0,
        amout_pjkp2u: 0,
        amout_jaster_handling: 0,
        amout_timbun: 0,
        amout_surcharge: 0,
        amount_shc: 0,
    }

    const [subTotal, setSubTotal] = useState({ ...subtotaldefault });
    const { agentCache, configCache } = useSelector(state => state.apps)

    const total_publish = agentRate === 0 ? ((subTotal?.publish_rate * awbsList?.weight) || 0) : 0;
    const total_agent = agentRate !== 0 ? ((agentRate * awbsList?.weight) || agentRate) : 0;
    const sub_total = total_publish + total_agent + subTotal?.amout_ra + subTotal?.amout_pjkp2u + subTotal?.amout_jaster_handling + subTotal?.amout_timbun + subTotal?.amout_surcharge + subTotal?.amount_shc;
    const total_ppn = Math.round(sub_total * configCache?.ppn / 100);
    const total_amount = sub_total + total_ppn;

    const handleClear = () => {
        setSubTotal({ ...subtotaldefault })
        setInputAwb(null)
        setAwbsList(null)
        form.resetFields();
        setRateAgent(0)
    };

    const handleSearch = () => {
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
        setAwbs([])
    };

    const handleInvoiceList = () => {
        setRefresh(moment().unix())
        setInvoiceListVisible(true);
    };

    const handleInvoiceListCancel = () => {
        setInvoiceListVisible(false);
    };


    const agentCacheFormat = agentCache?.map(item => ({
        value: item._id,
        label: item.name,
    }));

    const findAwb = () => {
        if (!inputAwb) return false;
        setdisbaleBtn(true)
        dispatch(GetAPI({ url: `api/v1/inquery_awb/incoming/${inputAwb}` }))
            .then(resp => {
                if (resp.payload?.error !== 0) {
                    setNotif("down")
                    handleSearch()
                } else if (resp.payload.data?.length === 0) {
                    setNotif("notfound")
                    handleSearch()
                } else {
                    setAwbs(resp.payload.data)
                    setNotif("success")
                    handleSearch()
                }
                setdisbaleBtn(false)
            })
    }

    const findAgent = (id) => {
        if (!id) return setRateAgent(0);
        dispatch(GetAPI({ url: `api/v1/agent/get/${id}` }))
            .then(resp => {
                setRateAgent(resp.payload.data?.rate)
            })
    }

    const saveAwb = (value) => {
        if (!awbsList?.awb_number) return false;
        setdisbaleBtn(true)

        const data = {
            ...value,
            ...awbsList,
            rate_agent: agentRate || 0,
            ppn: configCache?.ppn,
            total_publish,
            total_agent,
            sub_total,
            total_ppn,
            total_amount,
        }

        dispatch(PostAPI({ url: `api/v1/incoming_invoice/save`, data }))
            .then(resp => {
                message.success('save berhasil');
                handleClear()
                setdisbaleBtn(false)
                Print_Invoice({ ...resp?.payload, route: 'incoming' })
            })
    }

    const columns = [
        {
            title: 'AWB',
            dataIndex: 'awb_number',
            key: 'awb_number',
        },
        {
            title: 'Flight',
            dataIndex: 'flight_number',
            key: 'flight_number',
        },
        {
            title: 'Commodity',
            dataIndex: 'commodity',
            key: 'commodity',
        },
        {
            title: 'PCS',
            dataIndex: 'pieces',
            key: 'pieces',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
        },
    ];

    useEffect(() => {
        const agentPeroranganValue = agentRate === 0 ? awbsList?.agent_perorangan : "";

        form.setFieldsValue({
            agent_perorangan: agentPeroranganValue,
            publish_rate: agentRate === 0 ? configCache?.publish_rate : 0,
            weight: awbsList?.weight,
            pieces: awbsList?.pieces,
            amount_pjkp2u: awbsList?.amount_pjkp2u
        });

        // setSubTotal({ ...subTotal, publish_rate: configCache?.publish_rate });
    }, [awbsList, configCache, agentRate]);

    return (
        <>
            <Page title='Cashier Incoming' breadcrumb={breadcrumb} />
            <div style={{ display: 'flex', height: '100vh', marginTop: 10 }}>
                <Card title="Airwaybill Incoming" style={{ width: '60%', padding: '20px' }}>
                    <Form.Item style={{ marginBottom: '0' }}>
                        <Input
                            style={{ width: '20%', marginRight: '10px' }}
                            onChange={(value) => setInputAwb(value.target.value)}
                            value={inputAwb}
                            onPressEnter={() => findAwb()}
                        />
                        <Button disabled={disabledBtn} type="primary" onClick={() => findAwb()} icon={<SearchOutlined />}>
                            Search Awb
                        </Button>
                    </Form.Item>
                    <br />
                    <Table dataSource={[awbsList]} columns={columns} bordered size='small'></Table>
                    <Divider />
                    <Form
                        onFinish={(value) => saveAwb(value)}
                        form={form} labelCol={{ span: 10 }} wrapperCol={{ span: 16 }}
                    >
                        <div style={{ display: "flex" }}>
                            <div>
                                <Form.Item label="Agent" name="agent_id" style={{ marginTop: '20px' }}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={agentCacheFormat}
                                        onChange={(value) => findAgent(value)}
                                        allowClear={true}
                                    />
                                </Form.Item>
                                <Form.Item label="Perorangan" name="agent_perorangan" style={{ marginTop: '20px' }}>
                                    <Input
                                        disabled={agentRate !== 0}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setAwbsList({ ...awbsList, agent_perorangan: e.target.value })}
                                    />
                                </Form.Item>

                                <Form.Item label="Weight" name="weight" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        onChange={(value) => setAwbsList({ ...awbsList, weight: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="Pieces" name="pieces" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        onChange={(value) => setAwbsList({ ...awbsList, pieces: value })}
                                    />
                                </Form.Item>

                            </div>
                            <div>
                                <Form.Item label="Public Rate" name="publish_rate" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        disabled={agentRate !== 0}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, publish_rate: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="RA" name="amount_ra" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amout_ra: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="PJKP2U" name="amount_pjkp2u" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amout_pjkp2u: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="Jaster Handling" name="amount_jaster_handling" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amout_jaster_handling: value })}
                                    />
                                </Form.Item>
                            </div>

                            <div>
                                <Form.Item label="Timbun" name="amount_timbun" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amout_timbun: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="Surcharge" name="amount_surcharge" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amout_surcharge: value })}
                                    />
                                </Form.Item>
                                <Form.Item label="Shc Jaster" name="amount_shc" style={{ marginTop: '20px' }} rules={[{ required: true }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setSubTotal({ ...subTotal, amount_shc: value })}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <Divider />
                        <Form.Item style={{ marginTop: '20px', textAlign: 'left' }}>
                            <Space>
                                <Button htmlType="submit" type="primary" disabled={disabledBtn} icon={<CheckCircleOutlined />}>
                                    Save
                                </Button>
                                <Button onClick={handleClear} icon={<ClearOutlined />}>
                                    Clear
                                </Button>
                                <Button onClick={handleInvoiceList} icon={<FileTextOutlined />}>
                                    Invoice List
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
                <Card title="Rate" style={{ width: '40%', padding: '20px' }}>
                    <Descriptions size={"small"} column={1} contentStyle={{ display: 'flex', justifyContent: "flex-end" }}>
                        <Descriptions.Item label={`Publish Total Rp. ${numeral(subTotal?.publish_rate).format("0,000.00")} x ${awbsList?.weight || 0} Kg`}>Rp. {numeral(total_publish).format("0,000.00")}</Descriptions.Item>
                        <Descriptions.Item label={`Agent Total Rp. ${numeral(agentRate).format("0,000.00")} x ${awbsList?.weight || 0} Kg `}>Rp. {numeral(total_agent).format("0,000.00")}</Descriptions.Item>
                        <Descriptions.Item label="Sub Total">Rp. {numeral(sub_total).format("0,000.00")}</Descriptions.Item>
                        <Descriptions.Item label="PPN 1.1%">Rp. {numeral(total_ppn).format("0,000.00")}</Descriptions.Item>
                        <Descriptions.Item label="Total Amount">Rp. {numeral(total_amount).format("0,000.00")}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>

            <Modal
                title="Airwaybill"
                visible={modalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width={800}
            >
                <div>

                    {notif === "success" &&
                        <>
                            {awbs?.map((row, idx) => (
                                <>
                                    <Divider orientation="left" plain>
                                        {row?.awb_number}
                                    </Divider>
                                    <Descriptions size={"small"} column={3} key={idx} extra={<Button type="primary" onClick={() => {
                                        setAwbsList(row)
                                        handleModalCancel()
                                        setSubTotal({ ...subTotal, publish_rate: configCache?.publish_rate, amout_pjkp2u: row?.amount_pjkp2u })

                                    }} icon={<LikeOutlined />} >Select</Button>}>
                                        <Descriptions.Item label="Awb Number">{row?.awb_number}</Descriptions.Item>
                                        <Descriptions.Item label="Pieces">{row?.pieces}</Descriptions.Item>
                                        <Descriptions.Item label="Weight">{row?.weight}</Descriptions.Item>
                                        <Descriptions.Item label="Charged Weight">{row?.weight}</Descriptions.Item>
                                        <Descriptions.Item label="Dest">{row?.dest?.code}</Descriptions.Item>
                                        <Descriptions.Item label="Flight Number">{row?.flight_number}</Descriptions.Item>
                                        <Descriptions.Item label="Flight Date">{row?.flight_date}</Descriptions.Item>
                                        <Descriptions.Item label="Jaster">{row?.jaster}</Descriptions.Item>
                                        <Descriptions.Item label="Commodity">{row?.commodity}</Descriptions.Item>
                                        <Descriptions.Item label="SHC">{row?.shc}</Descriptions.Item>
                                        <Descriptions.Item label="Consignee Name">{row?.consignee?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Consignee Addr">{row?.consignee?.address}</Descriptions.Item>
                                    </Descriptions>
                                    <Divider />
                                </>
                            ))}
                        </>
                    }

                    {notif === "notfound" &&
                        <Result
                            status="warning"
                            title="Airwaybill tidak di temukan"
                            subTitle="Tolong check di portal jpt"
                        >
                        </Result>
                    }
                    {notif === "down" &&
                        <Result
                            status="error"
                            title="terjadi masalah get awb"
                            subTitle="masalah koneksi ke CIS 2.0, hubungi team"
                        >
                        </Result>
                    }
                </div>
            </Modal>

            <Modal
                title="Invoice List"
                visible={invoicesiListVisible}
                onCancel={handleInvoiceListCancel}
                footer={null}
                width={1500}
            >
                <InvoiceList refresh={refresh} setRefresh={setRefresh} />
            </Modal>
        </>
    )
}
