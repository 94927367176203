import MainRouter from './contents/MainRouter';
import './App.less';
import './index.less';
import './index.css';

function App() {
  return (
    <MainRouter />
  );
}

export default App;
