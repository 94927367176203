import Axios from 'axios';
import Config from '../config/env';
const { BaseUrl } = Config

const checkResponse = (resp, onTokenInvalid) => {
    if (resp.data) {
        const { error, message } = resp.data
        if (error === 0) return resp.data
        else if (error === 401) {
            if (typeof onTokenInvalid === 'function') onTokenInvalid()
            throw new Error("Session Login Expired")
        } else throw new Error(message)
    }
    throw new Error(`Unknown Error ${resp.statusText}`)
}

export const PostData = async (url, data, apps, onTokenInvalid) => {

    const tokenName = `srawung_token`
    const token = window.localStorage.getItem(tokenName);

    const resp = await Axios.post(url, data, {
        baseURL: BaseUrl,
        responseType: 'json',
        headers: {
            srawungtoken: token,
            'Content-Type': 'application/json'
        }
    })
    return checkResponse(resp, onTokenInvalid)
}

export const GetData = async (url, apps, onTokenInvalid) => {

    const tokenName = `srawung_token`
    const token = window.localStorage.getItem(tokenName);

    const resp = await Axios.get(url, {
        baseURL: BaseUrl,
        responseType: 'json',
        headers: {
            srawungtoken: token,
            'Content-Type': 'application/json'
        }
    })
    return checkResponse(resp, onTokenInvalid)
}
