import { Form, Input, Row, Col, Select, InputNumber } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";

const DefModel = {
    name: "",
    value: "",
    type: "string",
    field: "",
    multiple: [],
    isCreate: true,
};

const RenderFormString = () => {
    return (
        <Form.Item name={"value"} label="value" rules={[{ required: true }]} >
            <Input autoFocus size="middle" className="input-custom-md" autoComplete="off" />
        </Form.Item>
    )
}

const RenderFormNumber = () => {
    return (
        <Form.Item name={"value"} label="value" rules={[{ required: true }]} >
            <InputNumber style={{
                width: '100%',
            }} autoFocus size="middle" className="input-custom-md" autoComplete="off" />
        </Form.Item>
    )
}

const RenderFormArray = () => {
    return (
        <Form.Item name={"value"} label="value" rules={[{ required: true }]} >
            <Select
                mode="tags"
                style={{
                    width: '100%',
                }}
                showSearch={false}
            />
        </Form.Item>
    )
}

const RenderInput = (type) => {
    switch (type) {
        case 'string':
            return RenderFormString();
        case 'number':
            return RenderFormNumber();
        case 'array':
            return RenderFormArray();
        default:
            return RenderFormString();
    }
}

export default () => {
    const [model, setModel] = useState(DefModel);

    return (
        <FormPage
            url={"api/v1/config"}
            callbackPath={"/master/config"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Config"}
        >
            <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                    <Form.Item name={"name"} label="name" rules={[{ required: true }]} >
                        <Input autoFocus size="middle" className="input-custom-md" autoComplete="off" readOnly />
                    </Form.Item>
                    {
                        RenderInput(model?.type)
                    }
                </Col>
            </Row>
        </FormPage>
    );
}
