import React from 'react'
import { Avatar, Dropdown, Menu, Typography, Switch } from 'antd'
import { Link } from 'react-router-dom'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useTheme } from '../themes/use-theme';
import { useDispatch } from 'react-redux'
import { logout } from '../../redux'

export const UserProfile = ({ username, level, isColapsed }) => {
    const dispatch = useDispatch();
    const [darkMode, setDarkMode] = useTheme(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    const onLogout = () => {
        dispatch(logout())
    }

    const OverlayMenu = () => {
        return <Menu items={[
            {
                label: <Link onClick={() => onLogout()}>Logout</Link>,
                key: 'Logout',
                icon: <RiLogoutBoxRLine />,
            },
        ]} />
    }

    return (
        <div className='user-profile-wrapper' style={{ marginRight: isColapsed ? -20 : 170 }}>
            <DarkModeSwitch
                checked={darkMode}
                onChange={toggleDarkMode}
                size={16}
                moonColor="#ffb743"
                sunColor="white"
                style={{ marginRight: 20 }}
            />
            {/* <Switch translate="no" checkedChildren="EN" unCheckedChildren="ID" style={{ marginRight: 40 }} /> */}
            <Dropdown placement='bottomLeft' overlay={OverlayMenu} trigger={['click']}>
                <Avatar style={{ backgroundColor: '#3AB4F2', cursor: 'pointer' }} size={30}>{username}</Avatar>
            </Dropdown>
        </div>
    )
}