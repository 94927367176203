import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import numeral from 'numeral';
import { Button, Popover, Form, Input, message } from 'antd';
import { Print_Invoice } from '../../../../../components/printComp/invoice_server';
import { PrinterOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { PostAPI } from '../../../../../redux';


export default function KuitansiList({ refresh, setRefresh }) {

    const dispatch = useDispatch()

    const handleVoidAwb = (data) => {
        dispatch(PostAPI({ url: `api/v1/incoming_invoice/void`, data }))
            .then(resp => {
                if (!!resp?.payload) {
                    message.success('void berhasil');
                    setRefresh(moment().unix())
                }
                setRefresh(moment().unix())
            })
    }

    const columns = [
        {
            selector: (row) => row?.agent_id?.name || row?.agent_perorangan,
            name: "agent",
            id: 'agent'
        },
        {
            selector: (row) => row?.awb_number,
            name: "awb number",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.invoice_number,
            name: "invoice number",
            width: "200px",
            id: 'invoice_number'
        },
        {
            selector: (row) => row?.pieces,
            name: "pieces",
            id: 'pieces'
        },
        {
            selector: (row) => row?.weight,
            name: "weight",
            id: 'weight'
        },
        {
            selector: (row) => row?.status,
            name: "status",
            id: 'status'
        },
        {
            selector: (row) => "Rp. " + numeral(row?.total_publish).format("0,000.00"),
            name: "total publish",
            id: 'total_publish'
        },
        {
            selector: (row) => "Rp. " + numeral(row?.total_agent).format("0,000.00"),
            name: "total agent",
            id: 'total_agent'
        },
        {
            selector: (row) => "Rp. " + numeral(row?.sub_total).format("0,000.00"),
            name: "sub total",
            id: 'sub_total'
        },
        {
            selector: (row) => "Rp. " + numeral(row?.total_ppn).format("0,000.00"),
            name: "total ppn",
            id: 'total_ppn'
        },
        {
            selector: (row) => "Rp. " + numeral(row?.total_amount).format("0,000.00"),
            name: "total amount",
            id: 'total_amount'
        },

        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]

    const content = (uid) => {
        return (
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={(value) => handleVoidAwb({ ...value, uid })}
            >
                <Form.Item
                    label="reason"
                    name="void_reason"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your reason!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    };

    return (
        <Fragment>
            <TablePage
                title={"Outgoing Invoice"}
                url="api/v1/incoming_invoice"
                actionPosition={'first'}
                columns={columns}
                refresh={refresh}
                otherAction={(row, index) => {
                    return (
                        <div>
                            <Button
                                title="Print Label"
                                icon={<PrinterOutlined />}
                                shape="circle"
                                type="link"
                                onClick={() => {
                                    Print_Invoice({ ...row, route: 'incoming' })
                                }}
                            />
                            {row?.status !== "void" &&
                                <Popover
                                    content={() => content(row?._id)} placement="right" title="Void" trigger="click">
                                    <Button
                                        title="Void "
                                        style={{ color: 'red' }}
                                        icon={<CloseCircleOutlined color='red' />}
                                        shape="circle"
                                        type="link"
                                    />
                                </Popover>
                            }
                        </div>
                    )
                }}
            />
        </Fragment>
    )
}
