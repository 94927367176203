import React from 'react';
import {
    FcSettings, FcMoneyTransfer, FcBusinessman,
    FcShop, FcLeftUp, FcLeftDown, FcDataSheet,
    FcFactory, FcMultipleInputs, FcOvertime
} from "react-icons/fc";
import { Link } from 'react-router-dom';
const SIZE = 20;

export default [
    {
        // type: 'group',
        label: 'Master',
        level: 0x1ff0,
        dashed: true,
        children: [
            {
                key: '/master/config',
                icon: <FcSettings size={SIZE} />,
                label: <Link to={'/master/config'}>Config</Link>,
                level: 0x1ff0,
            },
            {
                key: '/master/airline',
                icon: <FcMultipleInputs size={SIZE} />,
                label: <Link to={'/master/airline'}>Airline</Link>,
                level: 0x1ff0,
            },
            {
                key: '/master/airport',
                icon: <FcFactory size={SIZE} />,
                label: <Link to={'/master/airport'}>Airport</Link>,
                level: 0x1ff0,
            },
            {
                key: '/master/agent',
                icon: <FcShop size={SIZE} />,
                label: <Link to={'/master/agent'}>Agent</Link>,
                level: 0x1ff0,
            },
            {
                key: '/master/rate',
                icon: <FcMoneyTransfer size={SIZE} />,
                label: <Link to={'/master/rate'}>Rate</Link>,
                level: 0x1ff0,
            },
            {
                key: '/master/user',
                icon: <FcBusinessman size={SIZE} />,
                label: <Link to={'/master/user'}>User</Link>,
                level: 0x1ff0,
            },
        ]
    },
    {
        type: 'group',
        label: 'Cashier',
        level: 0x1fff,
        dashed: true,
        children: [
            {
                key: '/cashier/outgoing',
                icon: <FcLeftUp size={SIZE} />,
                label: <Link to={'/cashier/outgoing'}>Outgoing</Link>,
                level: 0x1fff,
            },
            {
                key: '/cashier/incoming',
                icon: <FcLeftDown size={SIZE} />,
                label: <Link to={'/cashier/incoming'}>Incoming</Link>,
                level: 0x1fff,
            },
        ]
    },
    {
        type: 'group',
        label: 'Report',
        level: 0x1fff,
        dashed: true,
        children: [
            {
                key: '/report/outgoing',
                icon: <FcDataSheet size={SIZE} />,
                label: 'Outgoing',
                level: 0x1fff,
                children: [
                    {
                        key: '/report/outgoing/daily',
                        icon: <FcOvertime size={SIZE} />,
                        label: <Link to={'/report/outgoing/daily'}>Daily</Link>,
                        level: 0x1fff,
                    },
                    {
                        key: '/report/outgoing/monthly',
                        icon: <FcOvertime size={SIZE} />,
                        label: <Link to={'/report/outgoing/monthly'}>Monthly</Link>,
                        level: 0x1fff,
                    }
                ]
            },
            {
                key: '/report/incoming',
                icon: <FcDataSheet size={SIZE} />,
                label: 'Incoming',
                level: 0x1fff,
                children: [
                    {
                        key: '/report/incoming/daily',
                        icon: <FcOvertime size={SIZE} />,
                        label: <Link to={'/report/incoming/daily'}>Daily</Link>,
                        level: 0x1fff,
                    },
                    {
                        key: '/report/incoming/monthly',
                        icon: <FcOvertime size={SIZE} />,
                        label: <Link to={'/report/incoming/monthly'}>Monthly</Link>,
                        level: 0x1fff,
                    }
                ]
            }
        ]
    },
]