let de = {
    translations: {
        "Tool to shorten": "Tool to shorten a URL or reduce a link Use URL Shortener",
        "Paste here the url you want quickly": "paste here the url you want quickly",
        "Shorten": "Shorten",
        "Here you have full control over your links": "Here you have full control over your links",
        "Easy": "Easy",
        "ShortURL is easy and fast, enter the long link to get your shortened link": "ShortURL is easy and fast, enter the long link to get your shortened link",
        "Shortened": "Shortened",
        "Use any link, no matter what size, ShortURL always shortens": "Use any link, no matter what size, ShortURL always shortens",
        "Secure": "Secure",
        "It is fast and secure, our service have HTTPS protocol and data encryption": "It is fast and secure, our service have HTTPS protocol and data encryption",
        "Statistics": "Statistics",
        "Check the amount of clicks that your shortened url received": "Check the amount of clicks that your shortened url received",
        "Reliable": "Reliable",
        "All links that try to disseminate spam, viruses and malware are deleted": "All links that try to disseminate spam, viruses and malware are deleted",
        "Devices": "Devices",
        "Compatible with smartphones, tablets and desktop": "Compatible with smartphones, tablets and desktop",
        "Log In": "Log In",
        "Sign Up": "Sign Up",
        "Email": "Email",
        "Password": "Password",
        "Full Name": "Full Name",
        "Created by": "Created by"

    }
};

export default de;
