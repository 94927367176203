import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Dashboard from './dashboard';

export default () => {
    return (
        <BrowserRouter>
            <Route path='/'>
                <Dashboard />
            </Route>
        </BrowserRouter>
    )
}