import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PostAPI, GetAPIMe } from './apiHandling';
import jwtDecode from 'jwt-decode';
import { sendNotification } from "./utils";

const authSlice = createSlice({
    name: 'auth',
    initialState: { userdata: false, apps: '', initComplete: false },
    reducers: {
        successLogin(state, action) {
            const { userdata } = action.payload;
            state.userdata = { ...userdata };
        },
        setApps(state, action) {
            state.apps = action.payload
        },
        doLogout(state) {
            state.userdata = false
            state.token = ''
        },
        initComplete(state) {
            state.initComplete = true
        }
    }
})

export const { successLogin, setApps, doLogout, initComplete } = authSlice.actions

export const login = createAsyncThunk('auth/login', async ({ username, password }, thunkAPI) => {
    const { dispatch } = thunkAPI
    const tokenName = `srawung_token`
    const token = await dispatch(PostAPI({ url: 'auth/login', data: { username, password } }))
    if (!!token.payload) {
        const userdata = jwtDecode(token.payload)
        window.localStorage.setItem(tokenName, token.payload)
        dispatch(successLogin({ token: token.payload, userdata }))
        sendNotification("success", 'Welcome Dashboard 😎');
        return true;
    }
})

export const register = createAsyncThunk('auth/register', async (data, thunkAPI) => {
    const { dispatch } = thunkAPI
    const result = await dispatch(PostAPI({ url: 'auth/register', data }))
    if (!!result.payload) {
        sendNotification("success", 'Success Sign Up');
        return true;
    }
})

export const logout = createAsyncThunk('auth/logout', async (body, thunkAPI) => {
    const { dispatch } = thunkAPI
    await dispatch(GetAPIMe({ url: 'auth/logout' }))
    const tokenName = `srawung_token`
    window.localStorage.removeItem(tokenName)
    dispatch(doLogout())
})

export const initMe = createAsyncThunk('auth/me', async (body, thunkAPI) => {
    const { dispatch } = thunkAPI
    const tokenName = `srawung_token`
    const token = window.localStorage.getItem(tokenName)
    if (!!token) {
        const userdata = jwtDecode(token)
        dispatch(successLogin({ userdata, token }))
        dispatch(GetAPIMe({ url: 'auth/me' }))
        if (userdata) dispatch(successLogin({ userdata, token }))
    }
    dispatch(initComplete())
})

export default authSlice.reducer