import { useEffect, useState } from "react";

const TRANS_MODE = "trans-mode";

const getTransMode = () => JSON.parse(localStorage.getItem(TRANS_MODE)) || false;

export const useTrans = () => {
    const [transMode, setTransMode] = useState(getTransMode);

    useEffect(() => {
        const initialValue = getTransMode();
        if (initialValue !== transMode) {
            localStorage.setItem(TRANS_MODE, transMode);
            window.location.reload();
        }
    }, [transMode]);

    return [transMode, setTransMode];
};