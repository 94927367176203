import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
import Page from '../../../../../components/pages/Page';
import { levelToRole } from '../../../../../redux/reducer/levelConvert';

export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "Name",
            id: 'name'
        },
        {
            selector: (row) => row?.username,
            name: "Username",
            id: 'username'
        },
        {
            selector: 'level',
            name: 'Role',
            wrap: true,
            format: ({ level }) => levelToRole(level)
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <Fragment>
            <Page title='Master User' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master Airline"}
                url="api/v1/user/paging"
                actionPosition={'first'}
                createPath="/master/user/create"
                editPath={"/master/user/edit"}
                columns={columns}
            />
        </Fragment>
    )
}