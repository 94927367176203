import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAPI } from './apiHandling';
const Apps = createSlice({
    name: 'apps',
    initialState: {
        errorMessage: '', loading: false, versionCache: {},
        agentCache: [], configCache: {}
    },
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        cacheVersion(state, payload) {
            state.versionCache = payload.payload
        },
        setAgent(state, payload) {
            state.agentCache = payload.payload
        },
        setConfig(state, payload) {
            state.configCache = payload.payload
        },
    }
})

export const { setLoading, setAgent, unsetLoading, cacheVersion, setConfig } = Apps.actions
export const loadVersion = createAsyncThunk(
    'apps/load-version', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: `/version` }))
        if (resp?.payload?.data) dispatch(cacheVersion(resp.payload.data))
    }
)

export const loadAgents = createAsyncThunk(
    'apps/load-agent', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/agent/cache', noNotif: true }))
        if (resp?.payload?.data) dispatch(setAgent(resp.payload.data))
    }
)

export const loadCofigs = createAsyncThunk(
    'apps/load-configs', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/config/cache' }))
        if (resp?.payload?.data) dispatch(setConfig(resp.payload.data))
    }
)

export default Apps.reducer