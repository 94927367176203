import React, { Fragment } from 'react'
import Page from '../../../../../../components/pages/Page';
import FormReport from '../../../../../../components/pages/FormReport';
import { useFetch } from '../../../../../../hooks/useFetch';

export default function ReportOutgoing({ breadcrumb }) {
    return (
        <Fragment>
            <Page title='Report Outgoing' breadcrumb={breadcrumb} />
            <FormReport
                title={'Report Outgoing Monthly'}
                repType={'monthly'}
                url={"api/v1/report/outgoing"}
                FetchComponent={useFetch}
            />
        </Fragment>
    )
}
