import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import DashboardPages from './pages';
import { useDispatch, useSelector } from 'react-redux';
import { initMe, setApps } from '../../redux';
import SplashPage from '../../splash';
import LoginPage from '../home/login';
import HomePage from '../home';

const UserPage = ({ userdata }) => {
    return (
        <BrowserRouter basename='/'>
            <Switch>
                <Route exact path="/">
                    {userdata === false ? <HomePage /> : <Redirect to="/home" />}
                </Route>
                <Route path="/login">
                    {userdata === false ? <LoginPage apps={"Dashboard"} /> : <Redirect to={'/home'} />}
                </Route>
                <Route path="/*">
                    {(!!userdata && <DashboardPages idx={new Date().getTime()} />) || <Redirect to="/login" />}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default () => {
    const { userdata, initComplete } = useSelector(state => state.auth)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setApps('fr'));
        dispatch(initMe());
    }, [initMe])
    if (!initComplete) return <SplashPage />
    return <UserPage userdata={userdata} />
}