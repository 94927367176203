import React from 'react'
import {
    Layout
} from 'antd';
const { Footer } = Layout;

function Footers() {
    return (
        <Footer
            style={{ bottom: 0, width: "100%" }}
        >
            Copyright <a href='https://edifly-si.com' target='_blank'>EDIfly Solusi Indonesia</a>
        </Footer>
    )
}

export default Footers