import React, { useEffect } from 'react'
import { SiderLayout } from '../../../components/layouts/sider';
import Menus from './menu'
import { Routing } from './routing'
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { useLocHref } from '../../../hooks/useLocHref';
import { useMenu } from '../../../hooks/useMenu';
import { useNavs } from '../../../hooks/useNavs';
import { useDispatch, useSelector } from 'react-redux';
import { loadAgents, loadCofigs } from '../../../redux';
import Config from '../../../config/env'
const { Title } = Config

const TheRouter = () => {
    const routes = Routing;

    return (
        <Switch>
            {
                routes.map((route, idx) => (
                    <Route key={idx} path={route.to} exact={route.isExact !== false} render={props => {
                        return !!route.component && route.component
                    }} />
                ))
            }
        </Switch>
    )
}

export default () => {
    const key = useLocHref('');
    const { userdata: { level } } = useSelector(state => state.auth);
    const { keys, menus } = useMenu(Menus, level);
    useNavs(keys, key);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadAgents());
        dispatch(loadCofigs());
    }, []);

    return (
        <SiderLayout
            menus={menus}
            headerBgColor={"white"}
            headerColor={"black"}
            title={Title || ""}
        >
            <TheRouter />
        </SiderLayout>
    )
}