import React, { Fragment } from 'react';
import Page from '../../../../../components/pages/Page';
import TablePage from '../../../../../components/pages/TablePage';

export default function MasterConfig({ breadcrumb }) {
    const COLUMNS = [
        {
            selector: (row) => row?.name,
            name: 'Name',
            wrap: true,
        },
        {
            selector: (row) => row.value,
            name: 'Value',
            wrap: true,
            format: ({ value, type, fieldVal }) => {
                switch (type) {
                    case 'string':
                        return fieldVal === "password" ? "........." : value;
                    case 'object':
                        return value[`${fieldVal}`];
                    case 'array':
                        return value.join(', ');
                    case 'boolean':
                        return value.toString();
                    case 'boolean':
                        return value.toString();
                    default:
                        return value;
                }
            }
        },
    ]
    return (
        <Fragment>
            <Page title='Master Config' breadcrumb={breadcrumb} />
            <TablePage
                title={"Config"}
                url="api/v1/config"
                actionPosition={'first'}
                editPath={"/master/config/edit"}
                columns={COLUMNS}
            />
        </Fragment>
    )
}
