import MasterConfig from './master/config';
import ConfigForm from "./master/config/formConfig";
import AirlinePage from './master/airline';
import AirlineForm from './master/airline/form';
import AirportPage from './master/airport';
import AirportForm from './master/airport/form';
import ShcPage from './master/shc';
import ShcForm from './master/shc/form';

import MasterAgent from './master/agent';
import MasterForm from './master/agent/form';

import MasterRate from './master/rate';

import MasterUser from './master/user';
import MasterUsersForm from './master/user/form';

import CashierOutgoing from './cashier/outgoing';
import CashierIncoming from './cashier/incoming';

import ReportOutDaygoing from './report/outgoing/daily';
import ReportOutMontgoing from './report/outgoing/monthly';

import ReportDayIncoming from './report/incoming/daily';
import ReportMontIncoming from './report/incoming/monthly';


export const Routing = [
    // master
    {
        to: '/master/config',
        level: 0x1f00,
        component: <MasterConfig breadcrumb={['Master', 'Config']} />
    },
    {
        to: '/master/config/edit',
        level: 0x1f00,
        component: <ConfigForm breadcrumb={['Master', 'Edit Config']} />
    },
    {
        to: "/master/airline",
        level: 0x1f00,
        component: <AirlinePage breadcrumb={['Master', 'Airline']} />,
    },
    {
        to: "/master/airline/create",
        level: 0x1f00,
        component: <AirlineForm breadcrumb={['Master', 'Add Airline']} />,
    },
    {
        to: "/master/airline/edit",
        level: 0x1f00,
        component: <AirlineForm breadcrumb={['Master', 'Edit Airline']} />,
    },

    {
        to: "/master/airport",
        level: 0x1f00,
        component: <AirportPage breadcrumb={['Master', 'Airport']} />,
    },
    {
        to: "/master/airport/create",
        level: 0x1f00,
        component: <AirportForm breadcrumb={['Master', 'Add Airport']} />,
    },
    {
        to: "/master/airport/edit",
        level: 0x1f00,
        component: <AirportForm breadcrumb={['Master', 'Edit Airport']} />,
    },

    {
        to: "/master/shc",
        level: 0x1f00,
        component: <ShcPage breadcrumb={['Master', 'Shc']} />,
    },
    {
        to: "/master/shc/create",
        level: 0x1f00,
        component: <ShcForm breadcrumb={['Master', 'Add Shc']} />,
    },
    {
        to: "/master/shc/edit",
        level: 0x1f00,
        component: <ShcForm breadcrumb={['Master', 'Edit Shc']} />,
    },

    {
        to: '/master/agent',
        level: 0x1f00,
        component: <MasterAgent breadcrumb={['Master', 'Agent']} />
    },
    {
        to: '/master/agent/create',
        level: 0x1f00,
        component: <MasterForm breadcrumb={['Master', 'Add Agent']} />
    },
    {
        to: '/master/agent/edit',
        level: 0x1f00,
        component: <MasterForm breadcrumb={['Master', 'Edit Agent']} />
    },

    {
        to: '/master/rate',
        level: 0x1f00,
        component: <MasterRate breadcrumb={['Master', 'Rate']} />
    },
    {
        to: '/master/user',
        level: 0x1f00,
        component: <MasterUser breadcrumb={['Master', 'User']} />
    },
    {
        to: '/master/user/create',
        level: 0x1f00,
        component: <MasterUsersForm breadcrumb={['Master', 'Add User']} />
    },
    {
        to: '/master/user/edit',
        level: 0x1f00,
        component: <MasterUsersForm breadcrumb={['Master', 'Edit User']} />
    },

    // cashier
    {
        to: '/cashier/outgoing',
        level: 0x1f00,
        component: <CashierOutgoing breadcrumb={['Cashier', 'Outgoing']} />
    },
    {
        to: '/cashier/incoming',
        level: 0x1f00,
        component: <CashierIncoming breadcrumb={['Cashier', 'Incoming']} />
    },

    // report
    {
        to: '/report/outgoing/daily',
        level: 0x1f00,
        component: <ReportOutDaygoing breadcrumb={['Report', 'Outgoing Daily']} />
    },
    {
        to: '/report/outgoing/monthly',
        level: 0x1f00,
        component: <ReportOutMontgoing breadcrumb={['Report', 'Outgoing Monthly']} />
    },
    {
        to: '/report/incoming/daily',
        level: 0x1f00,
        component: <ReportDayIncoming breadcrumb={['Report', 'Incoming Daily']} />
    },
    {
        to: '/report/incoming/monthly',
        level: 0x1f00,
        component: <ReportMontIncoming breadcrumb={['Report', 'Incoming Monthly']} />
    },
]