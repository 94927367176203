import React from 'react';
import { Route, Switch } from "react-router-dom";
import Login from './login';
import './index.less';

export default () => {
    return (
        <Switch>
            <Route exact path="/">
                <Login />
            </Route>
        </Switch>
    )
}