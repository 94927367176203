import { useTrans } from "./use-trans";

import { ConfigProvider } from 'antd';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import frID from 'antd/es/locale/id_ID';
import frUS from 'antd/es/locale/en_US';

export const TransProvider = ({ children }) => {
    const [transMode] = useTrans();

    return (
        <>
            <ConfigProvider locale={transMode ? frID : frUS}>
                <I18nextProvider i18n={i18n}>
                    {children}
                </I18nextProvider>
            </ConfigProvider>
        </>
    );
};