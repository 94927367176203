import { Form, Input, InputNumber } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'

const DefModel = {
    name: '',
    email: '',
    rate: '',
}

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={'api/v1/agent'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Airline"}
            emptyModel={DefModel}
            callbackPath={"/master/agent"}
        >
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
                <Input size='middle' onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>
            <Form.Item name={'email'} label="Email" rules={[{ required: true }]}>
                <Input size='middle' />
            </Form.Item>
            <Form.Item name={'rate'} label="Rate" rules={[{ required: true }]}>
                <InputNumber size='middle' style={{ width: '100%' }} />
            </Form.Item>
        </FormPage>
    )
}