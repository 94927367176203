import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
import Page from '../../../../../components/pages/Page';

export default function MasterAgent({ breadcrumb }) {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "Name",
            id: 'name'
        },
        {
            selector: (row) => row?.rate,
            name: "Rate",
            id: 'rate'
        },
        {
            selector: (row) => row?.email,
            name: "Email",
            id: 'email'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <Fragment>
            <Page title='Master Agent' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master Agent"}
                url="api/v1/agent"
                actionPosition={'first'}
                createPath="/master/agent/create"
                editPath={"/master/agent/edit"}
                columns={columns}
            />
        </Fragment>
    )
}
