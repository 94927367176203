import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import FormPage from "../../../../../components/pages/FormPage";
const { Option } = Select;

const DefModel = {
    name: "",
    username: "",
    password: "",
    level: 0x01,
    isCreate: true
};

export default () => {
    const [model, setModel] = useState(DefModel);
    const levels = [
        {
            level: 0x1000,
            value: 'Super Admin'
        },
        {
            level: 0x01,
            value: 'Cashier',
        },
    ];
    return (
        <FormPage
            url={"api/v1/user"}
            callbackPath={"/master/user"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Users"}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
        >
            <Form.Item name={"name"} label="name">
                <Input className="input-custom-md" size="default" />
            </Form.Item>
            <Form.Item name={"username"} label="username" rules={[{ required: true }]}>
                <Input className="input-custom-md" size="default" />
            </Form.Item>
            <Form.Item name={"password"} label="password">
                <Input className="input-custom-md" size="default" />
            </Form.Item>
            <Form.Item name={"level"} label="level">
                <Select>
                    {levels.map((item) => (
                        <Option key={item.level} value={item.level}>
                            {item.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </FormPage>
    );
}